import axios from "axios";
export const FETCH_FEEDBACK_FULFILLED = "FETCH_FEEDBACK_FULFILLED";
export const FETCH_FEEDBACK_REJECTED = "FETCH_FEEDBACK_REJECTED";
export const FETCH_FEEDBACK_PENDING = "FETCH_FEEDBACK_PENDING";

export function feedback(data) {


    const formattedData = data
    const comment = JSON.stringify(formattedData)


    return (dispatch) => {
        dispatch({
            type: "FETCH_FEEDBACK",
            payload: axios({
                method: "post",
                url: `http://157.230.56.58:8096/api/feedback`,
                data: { comment },
                headers: {
                    "Accept-Language": "tr",
                    "Content-Type": "application/json",
                },
            }).then((result) => result.data)
        });
    };
}





