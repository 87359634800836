import { Routes, Route, useLocation, Navigate, useNavigate, BrowserRouter } from "react-router-dom";
import './App.css';
import { connect } from "react-redux";
import Index from './components/index'
import History from "./components/history";

function App(props) {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Index />}></Route>
        <Route path="/history" element={<History />}></Route>
      </Routes>
    </div>
  );
}

const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(App);
