import React, { useState, useEffect, CSSProperties } from 'react';
import RingLoader from 'react-spinners/RingLoader';
import Header from './header';
import { useNavigate } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { detectEtb } from '../actions/detectEtb';
import Swal from 'sweetalert2';
import { useTranslation, Trans } from 'react-i18next';
import { feedback } from '../actions/feedback';

const lngs = {
    en: { nativeName: 'English' },
    tr: { nativeName: 'Türkçe' },
    si: { nativeName: 'Slovenski jezik' }
};


const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
};

function Index(props) {
    function deleteAfterDashTR(str) {
        const index = str.indexOf("-TR");
        if (index !== -1) {
            return str.substring(0, index);
        } else {
            return str;
        }
    }

    const { t, i18n } = useTranslation();
    const [oldmessage,setOldmessage] = useState("");
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState('#7e5bee');
    const [feedbackCard, setFeedbackCard] = useState(false)
    const [feedbach, setFeedback] = useState("");
    const [feedbackStatus, setFeedbackStatus] = useState(true);

    const [mounted, setMounted] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [language, setLanguage] = useState(i18n.language)
    function handleSubmit(event) {
        event.preventDefault();
        setOldmessage(message)        
        dispatch(detectEtb(oldmessage+message, language));


    }

    function submitFeedback(e) {
        e.preventDefault();
        dispatch(feedback(feedbach))
    }



    useEffect(() => {
        // Dil değiştiğinde 'languageChanged' olayı tetiklenir
        const handleLanguageChanged = (lng) => {
            setLanguage(lng);
        };

        i18n.on('languageChanged', handleLanguageChanged);

        // Component unmount olduğunda olay dinleyicisini kaldır
        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, [i18n]);
    useEffect(() => {

        if (props.feedback.done === true) {
            Swal.fire({
                icon: 'success',
                title: t('Teşekkürler'),
                text: t('Geribildirinizi aldık en kısa zamanda geri dönüş yapacağız.'),
            });
            setFeedbackStatus(false);
            setFeedback("")
        }

    }, [props.feedback.done]);





    useEffect(() => {

        if (mounted && props.detectEtb.done === false) {
            if (Object.keys(props.detectEtb.error).length !== 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: t("Bir şeyler ters gitti."),
                })
            }

            setFeedbackCard(true)
        }
        if (((props.detectEtb.emotions && props.detectEtb.thoughts) === 1) && props.detectEtb.isOk === 0) {
            console.log("tramvatik vaka ")
        }
        setFeedbackStatus(true)
        setMounted(true);

    }, [props.detectEtb])


    return (
        <>
            <Header />

            <div className="container">
                <div className="row mt-3">
                    <div className="d-flex justify-content-center">
                        <div className="col-11">
                            <div className="card" style={
                                props.detectEtb.done ?
                                    { display: `none` }
                                    : null
                            }>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between mb-2 ">
                                        <h5></h5>
                                        <h5 className="text-start">
                                            {t("Duygu ve düşüncelerini analiz edebilmem için benimle paylaşım yapman önemli, ne kadar detaylı paylaşırsan seni o kadar iyi tanıyabilirim. Spesifik bir olaydan veya gününden genel olarak bahsedebilirsin.")}
                                        </h5>
                                        <hr />
                                    </div>
                                    <textarea
                                        placeholder={t("Sabah erkenden kalkıp uçağa yetişmem gerekiyordu. Biraz geç çıktım evden, yetişemeyeceğim telaşı sardı bir süre ama zamanında yetiştim uçağa. Uçak ile seyahat etmeyi sevmiyorum, özellikle hava kötüyken. Türbülans beni çok rahatsız ediyor ve korkutuyor. Uçak özellikle Istanbul’a inerken çok sallandı. Hava kapalı ve yağmurlu Istanbul’da. Arkadaşım son anda programımızı değiştirdi. Eve gidecekken şimdi çok daha uzak bir yola gidiyorum. Öbür havalimanına gidebilirdim böyle olacağını bilsem. Arkadaşımın doğum günü için pastasını aldım. Uzak doğu yemeğe geldik, yine çok yedim karnım çok şişti, bu bende biraz rahatsızlık yarattı. Akşam doğum günü partisi için çok heyecanlıyım, çok sevdiğim arkadaşlarımla çok sevdiğim bir restoranda kutluyoruz. Yemek çok iyidi, sonrasında eğlenceye geçtik, o da çok keyifliydi. Herkesin eğlenmesi beni çok mutlu etti. Geç saatte döndüm eve.")}
                                        onChange={(e) => {
                                            setMessage(e.target.value);
                                        }}
                                        name=""

                                        className="form-control"
                                        id=""
                                        cols="30"
                                        rows="10"
                                    ></textarea>
                                    <div style={props.detectEtb.spinner ? { display: '' } : { display: 'none' }} className="card spinner-on" id="spinnersk">
                                        <div className="card-body" id='spinner'>
                                            <RingLoader
                                                color={color}
                                                loading={loading}
                                                cssOverride={override}
                                                size={150}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                            <p className="mt-2">{t("İşleminiz devam ediyor. Lütfen bekleyiniz")}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end mt-2">
                                        {props.detectEtb.spinner ?
                                            <button className="btn btn-outline-primary mb-2 me-2" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                {t("Analiz ediliyor...")}
                                            </button>
                                            :
                                            <button onClick={(e) => { handleSubmit(e) }} className="btn btn-outline-primary mb-2 me-2">
                                                {t("Gönder")}
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            {props.detectEtb.done ?
                                <div className="card mt-4 border-0 response-card" >
                                    <div className="card-body">


                                        <>{props.detectEtb.detectetb.isOk ?
                                            <>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className='text-start'> <b>{t("Bilişsel Çarpıtma Analizi")}: </b> {props.detectEtb.detectetb.message.cognitiveDisorderResult} </p>
                                                        <p className='text-start'> <b>{t("Duygu Analizi")}: </b> {props.detectEtb.detectetb.message.emotionsDescription} </p>
                                                    </div>
                                                    <div className="col">
                                                        <img src={`data:img/png;base64, ` + props.detectEtb.detectetb.message.image['plutchik.png']} className='img-fluid' alt="" />

                                                    </div>
                                                </div>

                                            </>
                                            :
                                            props.detectEtb.detectetb.emotions && props.detectEtb.detectetb.thoughts === 1 ?
                                                props.detectEtb.detectetb.isOk === 0 ?
                                                    <div className='alert alert-danger'>
                                                        <p className='text-start'>{props.detectEtb.detectetb.message}</p>
                                                    </div>
                                                    : null
                                                :
                                                <p className='text-start'>{props.detectEtb.detectetb.message}</p>
                                        }</>
                                        {!props.detectEtb.detectetb.isOk ?
                                            <>

                                                <textarea
                                                    placeholder={t("inputtextarea")}
                                                    onChange={(e) => {
                                                        setMessage(e.target.value);
                                                    }}
                                                    name=""
                                                    disabled={
                                                        props.detectEtb.detectetb.emotions && props.detectEtb.detectetb.thoughts === 1 ?
                                                            props.detectEtb.detectetb.isOk === 0 ?
                                                                true
                                                                : null
                                                            : null
                                                    }
                                                    className="form-control"
                                                    id=""
                                                    cols="30"
                                                    rows="10"
                                                />
                                                <div className="d-flex justify-content-end mt-2">
                                                    {props.detectEtb.spinner ?
                                                        <button className="btn btn-outline-primary mb-2 me-2" type="button" disabled>
                                                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                            {t("Analiz ediliyor...")}
                                                        </button>
                                                        :
                                                        <button
                                                            disabled={
                                                                props.detectEtb.detectetb.emotions && props.detectEtb.detectetb.thoughts === 1 ?
                                                                    props.detectEtb.detectetb.isOk === 0 ?
                                                                        true
                                                                        : null
                                                                    : null
                                                            }
                                                            onClick={(e) => { handleSubmit(e) }} className="btn btn-outline-primary mb-2 me-2">
                                                            {t("Gönder")}
                                                        </button>
                                                    }
                                                </div>
                                            </>
                                            : null}
                                    </div>
                                </div>
                                : null}

                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-4 ">

                    <div
                        style={
                            !feedbackCard ?
                                { display: `none` }
                                : null
                        }
                        className="card mt-2 pb-0">
                        <div className="card-body">
                            <div className="mt-2 p-4 text-center">
                                <h5 className="mb-0 h5 pb mb-2">{t("Bize görüşlerinizden bahsedin")}</h5>
                                <small> {t("Yaşadığınız deneyimi birkaç cümle ile anlatabilirsiniz.")} </small>
                                <hr />
                                <div className="form-group mt-4">
                                    <textarea disabled={feedbackStatus ? false : true} value={feedbach} onChange={(e) => { setFeedback(e.target.value) }} className="form-control" rows="5" placeholder={t("Geribildiriminiz")} />
                                </div>
                                <div className="mt-2">
                                    <button disabled={feedbackStatus ? false : true} onClick={(e) => { submitFeedback(e) }} type="button" className="btn btn-outline-primary w-100"><span>{t("Geribildirim gönder")}</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
