import {
    /** FETCH PROFESSIONS  */
    FETCH_DETECTETB_FULFILLED,
    FETCH_DETECTETB_REJECTED,
    FETCH_DETECTETB_PENDING
} from "../actions/detectEtb";
const initialState =
{
    done: false,
    error: {},
    spinner: false,
}

export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_DETECTETB_PENDING:
            return {
                done: false,
                error: {},
                spinner: true,
            };
        case FETCH_DETECTETB_FULFILLED:
            return {
                ...state,
                detectetb: action.payload,
                done: true,
                error: {},
                spinner: false,
            };
        case FETCH_DETECTETB_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
            };
        default:
            return state;
    }
};