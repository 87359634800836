import axios from "axios";
export const FETCH_DETECTETB_FULFILLED = "FETCH_DETECTETB_FULFILLED";
export const FETCH_DETECTETB_REJECTED = "FETCH_DETECTETB_REJECTED";
export const FETCH_DETECTETB_PENDING = "FETCH_DETECTETB_PENDING";

export function detectEtb(data, lang) {

    console.log("dil" + lang)
    if (lang === "si") {
        lang = "sl"
    }
    const formattedData = data
    const responses = JSON.stringify(formattedData)


    return (dispatch) => {
        dispatch({
            type: "FETCH_DETECTETB",
            payload: axios({
                method: "post",
                url: `http://167.99.127.173:5002/detect`,
                data: { responses },
                headers: {
                    "Accept-Language": lang,
                    "Content-Type": "application/json",
                },
            }).then((result) => result.data)
        });
    };
}





