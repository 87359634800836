import React, { useState, useEffect, CSSProperties } from 'react';
import RingLoader from 'react-spinners/RingLoader';
import Header from './header';
import { useNavigate } from 'react-router';
import { connect, useDispatch } from 'react-redux';


function History(props) {

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState('#7e5bee');

    const [mounted, setMounted] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();



    return (
        <>
            <Header />

            <div className="container">
                <div className="row mt-3">
                    <div className="d-flex justify-content-center">
                        <div className="col-11">
                            <div className="card">
                                <div className="card-body">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(History);
