import React from 'react'
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const lngs = {
    en: { nativeName: 'English' },
    tr: { nativeName: 'Türkçe' },
    si: { nativeName: 'Slovenski jezik' }
};

function Header(props) {
    const { t, i18n } = useTranslation();
    function deleteAfterDashTR(str) {
        const index = str.indexOf("-TR");
        if (index !== -1) {
            return str.substring(0, index);
        } else {
            return str;
        }
    }
    const refreshPage = ()=>{
        window.location.reload();
     }


    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" href='#' onClick={(e)=>{refreshPage()}}>
                    <img src="/mentaskop-logo.png" className='img-fluid' width={170} alt="" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                   
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href='#' onClick={(e)=>{refreshPage()}}><i className="fa-thin fa-arrows-rotate"></i> {t('Yenile')}</a>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/history"><i className="fa-thin fa-head-side-brain"></i> {t("Bilişsel çarpıtma geçmişim")}</Link>
                        </li> */}

                    </ul>
                    <form className="d-flex">
                        
                        <select
                        className="form-select"
                            style={{ marginTop: 5, marginRight: 5 }}
                            onChange={(e) => {
                                i18n.changeLanguage(e.target.value)
                            }} name="" id="" >
                            {Object.keys(lngs).map((lng) => (
                                <option selected={`${lng == deleteAfterDashTR(i18n.language) ? `selected` : ``}`} key={lng} value={lng} >
                                    {lngs[lng].nativeName}
                                </option>
                            ))}
                        </select>
                    </form>
                </div>
            </div>
        </nav>
    )
}
const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);